export default {
  initPlyrControls: initPlyrControls,
  generateUniqueId: generateUniqueId,
  shuffleArray: shuffleArray,
  checkQuery: checkQuery,
  getById: getById
};

function initPlyrControls(plyr) {
  let plyrWidth = plyr.offsetWidth;
  plyr.querySelector('[data-plyr="pip"]').style.display =
    plyrWidth < 400 ? "none" : "";
  plyr.querySelector(".plyr__time--current").style.display =
    plyrWidth < 370 ? "none" : "";
  plyr.querySelector(".plyr__volume").style.display =
    plyrWidth < 340 ? "none" : "";
  plyr.querySelector(".plyr__time--duration").style.display =
    plyrWidth < 310 ? "none" : "";
}

function generateUniqueId() {
  const firstItem = {
    value: "0"
  };
  let counter = "123456789".split("").reduce((acc, curValue) => {
    const curObj = {};
    curObj.value = curValue;
    curObj.prev = acc;
    return curObj;
  }, firstItem);
  firstItem.prev = counter;
  return (function() {
    let now = Date.now();
    if (
      typeof performance === "object" &&
      typeof performance.now === "function"
    ) {
      now = performance
        .now()
        .toString()
        .replace(".", "");
    }
    counter = counter.prev;
    return `${now}${Math.random()
      .toString(16)
      .substr(2)}${counter.value}`;
  })();
}

function shuffleArray(array) {
  let shuffledArray = null;
  for (let count = 0; count < 10; count++) {
    shuffledArray = shuffle(array);
    if (JSON.stringify(array) !== JSON.stringify(shuffledArray)) {
      // if the shuffled array is different than the source one, return it
      return shuffledArray;
    }
  }
  // give up after 10 tries and just return the original array
  return array;
}

function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;
  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}

function checkQuery(str) {
  const URL_PARAMS = new URLSearchParams(window.location.search);
  if (URL_PARAMS.has(str)) {
    const PARAM = URL_PARAMS.get(str);
    if (PARAM === "0" || PARAM === "false") {
      return false;
    } else if (PARAM === "1" || PARAM === "true") {
      return true;
    } else {
      return PARAM;
    }
  } else {
    return null;
  }
}

function getById (id, arr) {
  if (!arr) {
    return {};
  }
  for (let i in arr) {
    const OBJ = arr[i];
    if (OBJ.id === id) {
      return OBJ;
    }
  }
  return {};
}
