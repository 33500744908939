<template>
  <v-container
    class="content-standard"
    bg
    fill-height
    grid-list-md
    text-xs-center
  >
    <v-layout row wrap align-center>
      <v-flex>
        <vue-plyr
          :ref="`plyr`"
          :emit="events"
          @play="onPlay"
          @pause="onPause"
          @timeupdate="onProgress"
        >
          <video :poster="poster" :src="src" disablePictureInPicture></video>
        </vue-plyr>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import analytics from "../../services/analytics";
import utilService from "@/services/utilService";
export default {
  name: "BlockVideo",
  computed: {
    src() {
      return this.block.video.startsWith("http")
        ? this.block.video
        : `video/${this.block.video}`;
    },
    poster() {
      return this.block.poster.startsWith("http")
        ? this.block.poster
        : `video/${this.block.poster}`;
    }
  },
  props: ["block"],
  data: () => ({
    uniqueId: "",
    playerRef: null,
    durationPlayed: 0,
    playStart: null,
    events: ["play", "pause", "timeupdate"],
    eventQueue: [],
    eventBufferMilliseconds: 750,
    lastEmit: 0
  }),
  methods: {
    onProgress(obj) {
      this.$hub.$emit("startScreenSaverCheck");
    },
    pauseAllMedia(obj) {
      if (!obj || this.uniqueId !== obj.except) {
        this.playerRef.pause();
      }
    },
    playMedia(obj) {
      if (obj.video === this.block.video) {
        this.playerRef.play();
      }
    },
    onPlay() {
      this.$hub.$emit("startScreenSaverCheck");
      window.$app.playing = true;
      this.playStart = new Date().valueOf();
      this.$hub.$emit("pauseAllMedia", {
        except: this.uniqueId
      });
      this.playerRef.muted = false;
      this.analytics("play");
    },
    analytics(state) {
      const retina_video = this.block.title.split("&ndash;").join("-");
      const retina_video_state = state;
      let rawTime = this.$refs.plyr?.player?.media?.currentTime;
      if (rawTime === null || rawTime === undefined) {
        rawTime = -1;
      }
      const retina_video_time = Math.round(rawTime * 10) / 10;
      analytics.video(retina_video, retina_video_state, retina_video_time);
    },
    onPause() {
      window.$app.playing = false;
      window.$app.startScreenSaverCheck();
      let percent = "";
      this.analytics("pause");
      if (this.playStart) {
        const DURATION_MS = new Date().valueOf() - this.playStart;
        this.durationPlayed += DURATION_MS;
        this.playStart = null;
        const TOTAL = this.$refs.plyr.player.duration * 1000;
        const RATIO = this.durationPlayed / TOTAL;
        percent = Math.ceil(RATIO * 100);
        if (percent > 100) {
          percent = 100;
        }
      }
    }
  },
  created() {
    this.uniqueId = utilService.generateUniqueId();
  },
  mounted() {
    this.$hub.$on("pauseAllMedia", this.pauseAllMedia);
    this.$hub.$on("playMedia", this.playMedia);
    this.playerRef = this.$refs.plyr.player;
    setTimeout(() => {
      utilService.initPlyrControls(this.$refs.plyr.$el);
    }, 10);
    if (this.block.auto) {
      this.playerRef.play();
    }
  },
  onBeforeDestroy() {
    this.$hub.$off("pauseAllMedia", this.pauseAllMedia);
    this.$hub.$off("playMedia", this.playMedia);
  }
};
</script>

<style lang="scss">
.plyr__control[data-plyr="mute"],
.plyr__control[data-plyr="pip"],
.plyr__volume {
  display: none !important;
}
.plyr__time {
  margin: 0 8px;
}
.plyr__video-wrapper {
  max-height: 40vh;
  video {
    max-height: 40vh;
  }
  .plyr__poster {
    max-height: 40vh;
  }
}
.tall .plyr__video-wrapper {
  max-height: calc(100vh - 360px);
  video {
    max-height: calc(100vh - 360px);
  }
  .plyr__poster {
    max-height: calc(100vh - 360px);
  }
}
.plyr:fullscreen {
  .plyr__video-wrapper {
    max-height: 100vh !important;
    video {
      max-height: 100vh !important;
    }
    .plyr__poster {
      max-height: 100vh !important;
    }
  }
}
</style>
